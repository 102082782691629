/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function phoneDisplaySpecial(profile, key = 'mainPhone') {
  if (!profile[key]) {
   return null;
  }
  let num = profile[key];
  if (key == 'c_providerLocationPhoneNumber') {
    num = num.replace("+", "");
    num = num.substring(0,3) + "-" + num.substring(3,6) + "-" + num.substring(6,10);
  } else {
    num = num.replace("+", "");
    num = num.substring(0,1) + "-" + num.substring(1,4) + "-" + num.substring(4,7) + "-" + num.substring(7,11);
  }
  return `${num}`;
}

// this will simply get the FIRST associated facility name with a Network Name of either OCMG or HCP
export function singleAssociatedFacility(profile) {
  const facilityArray = profile.c_associatedFacilityNames;
  let facilityNames;

  for (let i = 0; i < facilityArray.length; i++) {
    if (facilityArray[i].networkName === "OCMG" || facilityArray[i].networkName === "OptumCare HealthCarePartner California") {
      facilityNames = facilityArray[i].associatedFacilityNames;
      break;
    }
  }

  return facilityNames;
}

// this will get ALL associated facility names with a Network Name of either OCMG or HCP and create a list with each name separated by commas
export function allAssociatedFacilities(profile) {
    const facilityArray = profile.c_associatedFacilityNames;
    let facilityNamesArray = [];
    let facilityNamesString;

    // loop through to check each network/facilities pairing for SoCal networks
    for (let i = 0; i < facilityArray.length; i++) {
      // if a pairing is one of the SoCal networks, add the name to a facility names array
      if (facilityArray[i].networkName === "OCMG" || facilityArray[i].networkName === "OptumCare HealthCarePartner California") {
        facilityNamesArray.push(facilityArray[i].associatedFacilityNames);
      }
    }

    // turn the facility names array into a string with the list of all SoCal facility names
    facilityNamesString = facilityNamesArray.join(', ')

    // return the names string
    return facilityNamesString;
}
