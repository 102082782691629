// Returns a list of active facets/filters for adobe analytics
export function getFilters(appliedQueryFilters) {

  let activeFilters = [];

  if (appliedQueryFilters) {
    for (let [_, filter] of Object.entries(appliedQueryFilters)) {
      if (filter.value && !activeFilters.includes(filter.value)) {
        activeFilters.push(filter.value);
      }
    }
  }

  // Grabs active facet filters from answers core object
  let storage = ANSWERS.core.storage?.storage;
  if (!storage) return activeFilters;

  storage = Object.fromEntries(storage);
  if (!storage.facetFilters) return activeFilters;

  // Unpacking filter nested json structure
  for (let [filterName, i] of Object.entries(storage.facetFilters)) {
    let activeFilterValues = '';
    if (i.length) {
      for (let [_, j] of Object.entries(i)) {
        for (let [_, filter] of Object.entries(j)) {
          if (filter['$eq']) {
            if (activeFilterValues) activeFilterValues += '|';
            activeFilterValues += filter['$eq'];
          }
        }
      }
    }
    if (activeFilterValues) activeFilters.push(`${filterName}=${activeFilterValues}`);
  }

  return activeFilters;
}

export function getQuery() {
  let storage = ANSWERS.core.storage?.storage;
  if (!storage) return '';
  storage = Object.fromEntries(storage);
  return storage.query;
}

export function getVertical() {
  let storage = ANSWERS.core.storage?.storage;
  if (!storage) return '';
  storage = Object.fromEntries(storage);
  if (storage['universal-results']) {
    return 'site'
  } else {
    return 'provider'
  }
}

export function filterAnalytics() {
  const filterSection = document.querySelector('.js-answersFiltersWrapper');
  if (filterSection) {
    filterSection.addEventListener('click', (e) => {
      if (e.target.classList.contains('js-yext-filter-option') && e.target.tagName == "INPUT") {
        window.adobeDataLayer = window.adobeDataLayer || [];
        window.adobeDataLayer.push(
        {
          event: 'search filter',
          search: {
            term: HitchhikerJS.CustomModules.getQuery(),
            type: HitchhikerJS.CustomModules.getVertical(),
            filters: ANSWERS.core.storage.get('activeFilters'),
          }
        });

        const filterName = e.target.parentElement.querySelector('.js-yxt-FilterOptions-optionLabel--name').textContent;
        window.adobeDataLayer.push({
          event: 'link track',
          data: {
            link: {
              name: filterName,
              location: "section 2 : Yext search",
              type: "internal"
            }
          }
        });
      }
    });
    var observer = new MutationObserver(function(mutations) {
      let mutation = mutations[0];
      if (mutation.target) {
        let collapsed = mutation.target.querySelector('.yxt-FilterOptions--collapsed');
        if (!collapsed) {
          window.adobeDataLayer.push({
            event: 'link track',
            data: {
              link: {
                name: "Expand Facet",
                location: "section 2 : Yext search",
                type: "internal"
              }
            }
          });
        }
      }
    });
    observer.observe(filterSection, {attributes: false, childList: true, characterData: false, subtree:true});
  }
}

export function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-337086771/WdXPCIXnveQDELOS3qAB',
      'event_callback': callback
  });
  return false;
}